/* eslint-disable*/

import {differenceInMinutes} from "date-fns";
import {EWebinarSourceType, IAdsLayout, IAdTags, IUser} from "../types";
import axios from "axios";
import {parseUrl, stringifyUrl} from "query-string";

export * as cssHidden from "../MUI/_Theme.scss";

export const extractCourseId = () => {
  const matches = /course_id=(\d*)/.exec(document.location.search);
  return matches ? matches[1] : null;
};
export function validateUri(uri?: string) {
  if (!uri) return true;
  return /(http(s?)):\/\//i.test(uri);
}
export const getDateHMDiff = (startDate: any, endDate: any) => {
  const dateInMinutes = differenceInMinutes(startDate, endDate) || 0;
  const hours = Math.floor(Math.abs(dateInMinutes / 60));
  const minutes = Math.abs(dateInMinutes % 60);

  return {minutes, hours};
};

export const upperFirstCase = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const textRichToPlain = (text: string) => {
  return text
    ? text
      .replace(/^\r\n|\r|\n$/gm, "")
      .replace(/<\/?[^>]+(>|)/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
    : "";
};

export const textPlainToRich = (text: string) => {
  return text
    ? "<p>" +
    text
      .replace("/&/g", "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\r?\n|\r/g, "&nbsp;</p>\r\n<p>") +
    "</p>"
    : "";
};

const shortNumber = (data: number) => {
  return Math.max(0, 3 - String(Math.floor(data)).length);
};
/*
const translator: any = (window as any).$.translator,
  locales: any = {
    filesizes: [
      translator.translate("Б"),
      translator.translate("Кб"),
      translator.translate("Мб"),
      translator.translate("Гб"),
    ],
  };*/
/*

export const filesizeHumanize = (
  filesize: number,
  noFloat: boolean = false
) => {
  if (filesize <= 0) {
    return "0 " + locales.filesizes[0];
  } else if (filesize < 1024) {
    return filesize + " " + locales.filesizes[0];
  } else if (filesize > 1024 && filesize < 1048576) {
    const num = filesize / 1024;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[1]
    );
  } else if (filesize > 1048576 && filesize < 1073741824) {
    const num = filesize / 1048576;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[2]
    );
  } else {
    const num = filesize / 1073741824;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[3]
    );
  }
};
*/

export const numberMorfform = (
  data: number,
  num1: string,
  num2: string,
  num3: string
) => {
  const num = Math.abs(data);
  if (num % 100 > 4 && num % 100 < 15) {
    return num3;
  } else if (num % 10 > 1 && num % 10 < 5) {
    return num2;
  } else if (num % 10 == 1) {
    return num1;
  } else {
    return num3;
  }
};

export const _viewport = () => {
  let w: any = window,
    a = "inner";
  if (!("innerWidth" in window)) {
    a = "client";
    w = document.documentElement || document.body;
  }
  return {
    width: w[a + "Width"],
    height: w[a + "Height"],
  };
};

export const pluralMorfForm = (data: number, form1: string, form2: string) => {
  if (Math.abs(data) > 1) {
    return form2;
  }
  return form1;
};

export const capitlalize = (word: string) => {
  return word[0].toUpperCase() + word.substring(1);
};

export const HTML2React = (text: string) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(
    `<!doctype html><body>${text}`,
    "text/html"
  ).body.textContent;
  return decodedString;
};

export const tl = (text: string) => {
  return text;
  //(window as any).$.translator.translate(text);
}

export const copyToClipboard = async (text: string, callback?: () => void) => {
  // Permission API автоматически дает доступ на clipboard-write в активной вкладке,
  // поэтому здесь его использовать не нужно

  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      callback && callback();
    } catch (error) {
      console.log('Не удалось скопировать:', error);
    }
  } else {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (callback) {
      callback();
    }
  }
};

export const typeCastToIFilesList = (files: any) => {
  if (Array.isArray(files)) {
    const fileList = files.map((file) => {
      return typeCastToIFile(file);
    });
    return fileList;
  } else return typeCastToIFile(files);
};

export const typeCastToIFile = (file: any) => {
  return {
    id: file.id,
    category: file.category,
    name: getName(file.file),
    extension: getExtension(file.file),
    size: file.size,
    attachedCode: file.code,
    srcUrl: file.src,
    icon: file.icon,
  };
};

export const getName = (file: string) => {
  return file.split(".").shift();
};

export const getExtension = (file: string) => {
  var ext = file.split(".").pop();
  return ext ? "." + ext : "";
};

export const beautyNumbers = (
  num: string | number,
  separator: string = "."
) => {
  const arr = num.toString().split(separator);
  return (
    arr[0].replace(/(\d)(?=(?:\d{3})+\b)/gm, "$& ") +
    (arr[1] ? "." + arr[1] : "")
  );
};

export const getFileCodes = (files: any) => {
  let codes = "";
  files.map((file: any, i: number) => {
    codes += file.attachedCode;
    if (i !== files.length - 1) {
      codes += "\n";
    }
  });
  return codes;
};

export const emailValidation = (email: string) => {
  return /.+@.+\..+/i.test(email);
};

export const phoneValidation = (phone: string) => {
  return /^[\+]?\d{2,}?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im.test(
    phone
  );
};

export const hextorgba = (color: string, opacity: number) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    c = color.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  return color;
};

const getRgb = (color: string | number[]) => {
  let rgb;
  if (typeof color === "string") {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    rgb = result
      ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
      : [0, 0, 0];
  } else {
    rgb = color;
  }
  return rgb
}

export const toHSV = (data: string | number[]) => {
  const [r, g, b] = getRgb(data);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;
  let h;
  const s = max === 0 ? 0 : d / max;
  const v = max / 255;

  switch (max) {
    case min:
      h = 0;
      break;
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6 * d;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6 * d;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6 * d;
      break;
  }

  return [h, s, v];
};

export const fromHSV = (data: number[]) => {
  const [h, s, v] = data;
  let r =0 , g=0, b=0, i=0, f=0, p=0, q=0, t=0;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
  }

  return (
    `#` +
    `0${Math.round(r * 255).toString(16)}`.slice(-2) +
    `0${Math.round(g * 255).toString(16)}`.slice(-2) +
    `0${Math.round(b * 255).toString(16)}`.slice(-2)
  );
};

export const rgba = (color: string, opacity: number = 1) => {
  const [r, g, b] = getRgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
}

export const fromRgba = (color: string) => {
  const arr = color.match(
    /(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,?\s*([\d\.]+)?\s*)/
  );
  if (arr) {
    const r = +arr[2];
    const g = +arr[3];
    const b = +arr[4];
    const opacity = arr[5] ? +arr[5] * 100 : 100;
    const hex =
      `#` +
      `0${r.toString(16)}`.slice(-2) +
      `0${g.toString(16)}`.slice(-2) +
      `0${b.toString(16)}`.slice(-2);
    return {
      hex: hex,
      opacity: opacity,
    };
  } else {
    return {
      hex: '#fff',
      opacity: 0.1,
    };
  }
}

export type ModifyType<T, R> = Omit<T, keyof R> & R;

export const scrollTo = (top: number = 0, speed: number = 500) => {
  const initialY = document.body.scrollTop;
  const y = initialY + top;
  const baseY = (initialY + y) * 0.5;
  const difference = initialY - baseY;
  const startTime = performance.now();

  function step() {
    var normalizedTime = (performance.now() - startTime) / speed;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
};


export const getVideoSourceTypeByLink = (link: string) => {
  if (link) {
    try {
      const url = new URL(link);
      switch (url.hostname) {
        case 'play.boomstream.com':
        case 'bs.boomstream.com':
          return EWebinarSourceType.BOOMSTREAM;
        case 'youtube.com':
        case 'www.youtube.com':
          return EWebinarSourceType.YOUTUBE;
        case 'vimeo.com':
          return EWebinarSourceType.VIMEO;
        case 'kinescope.io':
          return EWebinarSourceType.KINESCOPE;
        case 'rutube.ru':
          return EWebinarSourceType.RUTUBE;
        case 'vk.com':
          return EWebinarSourceType.VK;
      }
    } catch(e) {
      return EWebinarSourceType.YOUTUBE;
    }
  }
  return EWebinarSourceType.YOUTUBE;
};

export const getKinescopeVideoId = (link: string) => {
  const url = new URL(link);
  return url.pathname.replace('/','');
}

export const getYoutubePoster = (link:string):string => {
  try {
    const url = new URL(link);
    const id = url.search.split('v=')[1];
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg;`;
  }
  catch (e){
    return '';
  }
}
export const getVimeoPoster = async (link:string) => {
  try {
    const result = await axios.get(`https://vimeo.com/api/oembed.json?url=${link}`);
    if (result.data) {
      return result.data.thumbnail_url;
    }
  }
  catch (e){
    return '';
  }
}

export const getBoomstreamVideo = async (link:string, apikey?: string) => {
  if (!apikey) {
    return null;
  }
  const url = new URL(link);
  let result = await axios.get(`https://boomstream.com/api/media/info?apikey=${apikey}&code=${url.pathname.substring(1)}&format=json`);
  if(result.data.Status === 'Success'){
    const link = result.data.Transcodes[result.data.Transcodes.length - 1];
    return {
      videoLink: link.PseudoMP4,
      poster: result.data.Poster.Url
    }
  }
  result = await axios.get(`https://boomstream.com/api/live/info?apikey=${apikey}&code=${url.pathname.substring(1)}&format=json`);
  if(result.data.Status === 'Success'){
    const link = result.data.Recordings[0].Transcodes[result.data.Recordings[0].Transcodes.length - 1];
    return {
      videoLink: link.PseudoMP4,
      poster: result.data.Poster.Url
    }
  }
  if(result.data.Status === 'Failed' && result.data.Message === 'Application not found') {
    throw new Error('api');
  }
  if(result.data.Status === 'Failed' && result.data.Message === 'Media not found') {
    throw new Error('uri');
  }
  return null;
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    //@ts-ignore
    navigator.userAgent || navigator.vendor || window.opera
  );
  return check;
}

export const prettifyDuration = (duration: number, long?: boolean) => {
  if (duration < 60) return `${long ? 'менее ' : '<'}1 мин.`;
  const h = Math.floor(duration / 3600);
  const m = Math.floor(duration / 60 - h * 60);
  return `${h ? `${h}ч. ` : ''}${m} мин.`;
};

export const base64toFile = (b64Data: any, name: string, contentType: string, sliceSize = 512): File => {
  const byteCharacters = atob(b64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob: any = new Blob(byteArrays, { type: contentType });
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return (blob as File);
}
export const buildLink = (link:string, adTags?:IAdTags):string => {
  const url = parseUrl(link, {parseFragmentIdentifier: true})
  if (adTags) {
    return stringifyUrl({...url, query: {...url.query,...adTags}, fragmentIdentifier: url.fragmentIdentifier});
  }
  return stringifyUrl(url);
}

export const appendScript = (content:string | null) => {
  if(!content) return;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = content;
  document.head.appendChild(script);
}

export function translit(word: string){
	let answer = '';
	const converter: {
    [key: string]: string
  } = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',   ' ': '_',
	};

	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
			answer += word[i];
		} else {
			answer += converter[word[i]];
		}
	}

	return answer;
}


export function userQueryParamsValidator (data: Partial<IUser>, requiredFields?: {[key: string]: any}) {
  if (!data.name) {
    return false;
  }
  if (!requiredFields) {
    return true;
  } else {
    return Object.keys(requiredFields).every(key => {
      if (requiredFields[key]) {
         return !!data[key];
      }
      return true;
    });
  }
}

export function getAdsLayoutSize (ads: Array<IAdsLayout>): boolean {
  if (ads.length > 0) {
    if (ads.length === 1) {
      const currentAd = ads[0];
      if (currentAd.timer) {
        if (currentAd.buttons.length === 0) {
          return true;
        }
        if (currentAd.buttons.length <= 2 && !currentAd.banner) {
          return true;
        }
      } else {
        if (currentAd.buttons.length <= 4 && !currentAd.banner) {
          return true;
        }
        if (currentAd.buttons.length === 0 && currentAd.banner) {
          return true;
        }
      }
    } else {
      return false;
    }
  }
  return false;
}

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;