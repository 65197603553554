import { differenceInSeconds } from 'date-fns';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useAudioContext from '../../../hooks/useAudioContext';
import { VideoPlayerCtx, VideoPlayerProps } from './factory';
import { PlayButton } from '../PlayButton';
import useVisibilityChange from '../../../hooks/useVisibilityChange';
import { isMobile } from 'react-device-detect';

const makeLink = (url: string) => {
  const [, oid, id] = url.match(/vk.com\/video(.*)_(.*)/) || [];
  return `https://vk.com/video_ext.php?oid=${oid}&id=${id}&hd=2&js_api=1`;
}

export const VKVideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const playerRef = useRef<HTMLIFrameElement | null>(null);
  const { volume, muted, setMuted} = useAudioContext();
  const {setPlaying, setStarted, setMask, handleMask, hasStarted, setIgnoreCurtain} = useContext(VideoPlayerCtx);
  
  useEffect(() => {
    setPlaying(true);
    setIgnoreCurtain(true);
    const player: any = (window as any).VK.VideoPlayer(playerRef.current);
    player.on('started', () => {
      handleMask();
      setPlaying(true);
      setStarted(true);
      setIgnoreCurtain(false);
    });
  }, []);

  useEffect(() => {
    const player: any = (window as any).VK.VideoPlayer(playerRef.current);
    player?.setVolume(volume / 100);
  }, [volume]);

  useEffect(() => {
    const player: any = (window as any).VK.VideoPlayer(playerRef.current);
    if (muted) {
      player?.mute();
    } else {
      player?.unmute();
      player?.setVolume(volume / 100);
    }
  }, [muted]);

  useVisibilityChange({
    onHidden: () => {
      setMask(true);
      if (isMobile) {
        setMuted(true);
        setPlaying(false);
      }
    },
    onVisible: () => {
      setMask(true);
      handleMask();
      if (isMobile) {
        /*
          следи за руками:
            когда происходит плей, ютуб по куке ищет последнее место отсановки видео и сам туда мотает его
            потом срабатывает синхра.
            для остальных плееров лучше в начале синхронизировать, а потом уже включать
            так дешевле для клиента
        */
        synchronize();
        setMuted(true);
        setPlaying(true);
      }
    },
  });

  const synchronize = useCallback(() => {
    const player: any = (window as any).VK.VideoPlayer(playerRef.current);
    if (!player) return;
    let streamDuration = differenceInSeconds(Date.now(), props.startTime || Date.now());
    console.log('sync event started');
    console.log({streamDuration});
    if (
      props.startTime !== undefined &&
      streamDuration >= 0 &&
      hasStarted
    ) {
      if (!props.live && props.seekTime) {
        streamDuration += props.seekTime
      }

      if(props.live) {
        console.log('seekTo Infinity');
        player.seek(99999);
      } else {
        console.log('seekTo ' + streamDuration);
        player.seek(streamDuration);
      }
    }

  }, [hasStarted, props.startTime, props.live, props.seekTime, playerRef]);

  useEffect(() => {
    synchronize();
    const id = setInterval(synchronize, 3 * 60 * 1000); // every 5 minutes
    return () => clearInterval(id);
  }, [synchronize]);

  const handlePlay = () => {
    handleMask();
    setPlaying(true);
    setIgnoreCurtain(false);
    console.log('play reinitiated', new Date().toString());
    const player: any = (window as any).VK.VideoPlayer(playerRef.current);
    if (player) {
      player.play();
      setStarted(true);
    }
  }
  
  return (
    <>
    <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}>
      <iframe ref={playerRef} title="rutube player" width="100%" height="100%" src={makeLink(props.src)} frameBorder="0" allow="autoplay"></iframe>
    </div>
    {!hasStarted &&  <PlayButton onClick={handlePlay} />}
    </>
  )
}